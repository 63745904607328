import {findAll} from "../utils/dom";
import {preventEvent} from "../utils/etc";

class ExpertRequestBlock {
    constructor(wrapper) {
        this.wrapper = wrapper;

        this.requestBtn = wrapper.querySelector('[data-role="request-btn"]');
        this.requestBtn.addEventListener('click', this.requestBtnClickHandler.bind(this));

        this.declineBtn = wrapper.querySelector('[data-role="decline-btn"]');
        this.declineBtn.addEventListener('click', this.declineBtnClickHandler.bind(this));

        this.removeBtn = wrapper.querySelector('[data-role="remove-btn"]');
        this.removeBtn.addEventListener('click', this.removeBtnClickHandler.bind(this));

        this.requestBlock = wrapper.querySelector('[data-role="requests-block"]');
        this.removeBlock = wrapper.querySelector('[data-role="remove-block"]');
    }

    removeBtnClickHandler(event) {
        preventEvent(event);

        if (!confirm(window.__moni.translations.removeMeQuestion)) {
            return;
        }

        const {url, userId, companyId} = event.target.dataset;

        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('companyId', companyId);

        this.request(url, formData)
            .then(_ => {
                this.loadEnd();
                this.showRequestBlock();

                return true;
            })
            .catch(err => {
                this.loadEnd();
                console.error(err)
            });
    }

    requestBtnClickHandler(event) {
        preventEvent(event);

        this.loadStart();

        this.request(event.target.dataset.url)
            .then(json => {
                this.loadEnd();
                this.showDeclineBtn();

                return true;
            })
            .catch(err => {
                this.loadEnd();
                console.error(err)
            });
    }

    declineBtnClickHandler(event) {
        preventEvent(event);

        this.request(event.target.dataset.url)
            .then(json => {
                this.loadEnd();
                this.showRequestBtn();
            })
            .catch(err => {
                this.loadEnd();
                console.error(err)
            });
    }

    request(url, body) {
        this.loadStart();

        return fetch(url, {
            method: 'POST',
            body: body,
        })
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(response.text());
                }

                return response.json();
            })
    }

    showRequestBtn() {
        requestAnimationFrame(() => {
            this.requestBtn.style.display = '';
            this.declineBtn.style.display = 'none';
        });
    }

    showDeclineBtn() {
        requestAnimationFrame(() => {
            this.requestBtn.style.display = 'none';
            this.declineBtn.style.display = '';
        });
    }

    showRequestBlock() {
        requestAnimationFrame(() => {
            this.requestBlock.style.display = '';
            this.removeBlock.style.display = 'none';
        });
    }

    showRemoveBlock() {
        requestAnimationFrame(() => {
            this.requestBlock.style.display = 'none';
            this.removeBlock.style.display = '';
        });
    }

    loadStart() {
        this.wrapper.classList.add('loading');
    }

    loadEnd() {
        this.wrapper.classList.remove('loading');
    }
}

export default (context = document) => {
    const expertRequests = findAll(context, '[data-component="expert-request-toolbar"]');
    for (const component of expertRequests) {
        new ExpertRequestBlock(component);
    }
};
