import IMask from 'imask';

export function createSingleDatePicker(input, opts) {
  if (typeof input === 'string') {
    input = document.querySelector(input);
  }

  if (input.tagName === 'INPUT') {
    new IMask(input, {
      autofix: true,
      mask: IMask.MaskedDate,
    });
    input.readOnly = false;
  }
  delete opts.dateFormat;

  return $(input).datepicker(opts);
}

export function createDatePicker(el, opts) {
  $(el).each(function (_, input) {
    createSingleDatePicker(input, opts);
  });
}
