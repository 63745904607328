import {findAll} from "../utils/dom";

class CustomSelect {
    constructor(select) {
        $(select).select2(); //@TODO: replace with real custom select
    }
}

export default (context = document) => {
    const items = findAll(context, '[data-component="custom-select"]');
    for (const item of items) {
        new CustomSelect(item);
    }
}