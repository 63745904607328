import {findAll, listenElement} from '../utils/dom';
import {preventEvent} from '../utils/etc';

class FlashNotification {
  constructor(wrapper) {
    this.wrapper = wrapper;

    listenElement(
        wrapper,
        '[data-role="close-btn"]',
        'click',
        this.closeBtnClickHandler.bind(this)
    );
  }

  closeBtnClickHandler(event) {
    preventEvent(event);

    const wrapper = this.wrapper.parentNode;
    if (!wrapper) {
      return;
    }

    requestAnimationFrame(() => {
      wrapper.removeChild(this.wrapper);
    });
  }
}

export default (context = document) => {
  const flashes = findAll(context, '[data-component="flash-notification"]');
  for (const elem of flashes) {
    new FlashNotification(elem);
  }
};