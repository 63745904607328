import {preventEvent} from '../../utils/etc';

export class RangedDataPicker {
    constructor(wrapper) {
        this.rangeField = document.getElementById(wrapper.getAttribute('data-input-ranged-id'));
        this.startDateField = document.getElementById(wrapper.getAttribute('data-input-start-date-id'));
        this.endDateField = document.getElementById(wrapper.getAttribute('data-input-end-date-id'));
        this.params = {};
        this.translations = window.__moni.components.rangeDatePicker.translations;

        if (wrapper.getAttribute('data-ranger-params')) {
            this.params = JSON.parse(wrapper.getAttribute('data-ranger-params'));
        }

        if (!this.rangeField || !this.startDateField || !this.endDateField) {
            console.log('fields are not defined');
            return;
        }
        wrapper.insertAdjacentHTML('beforeend', this.template());

        this.rangedPicker = wrapper.querySelector('.ranged-data-picker');
        this.startDatePicker = createSingleDatePicker(this.rangedPicker.querySelector('.ranged-data-picker__start'), {
            changeYear: true,
            inline: true,
            minDate: (this.params['dateMin']) ? this.params['dateMin'] : "now",
            maxDate: (this.params['dateMax']) ? this.params['dateMax'] : "now",
            defaultDate: this.params['startNow'],
            yearRange: "1920:now",
            dateFormat: "yy-mm-dd"
        });

        this.endDatePicker = createSingleDatePicker(this.rangedPicker.querySelector('.ranged-data-picker__end'), {
            changeYear: true,
            inline: true,
            minDate: (this.params['dateMin']) ? this.params['dateMin'] : "now",
            maxDate: (this.params['dateMax']) ? this.params['dateMax'] : "now",
            defaultDate: this.params['endNow'],
            yearRange: "1920:now",
            dateFormat: "yy-mm-dd"
        });

        this.rangedPicker.querySelector('.close').addEventListener('click', this.close.bind(this));
        this.rangedPicker.querySelector('.set-range-js').addEventListener('click', this.setRange.bind(this));
        this.rangedPicker.querySelector('.cancel-range-js').addEventListener('click', this.cancel.bind(this));
        // this.rangedPicker.querySelector('.set-today-js').addEventListener('click', this.setToday.bind(this));

        this.rangeField.addEventListener('click', (e) => {
            preventEvent(e);
            this.rangedPicker.classList.toggle('active');
        })
    }

    template() {
        return `<div class="ranged-data-picker" data-cy="ranged-dp-modal">
        <div class="ranged-data-picker__wrapper">
            <div class="close">
                <span class="material-icons">clear</span>
            </div>   
            <div class="ranged-data-picker__header">
                <div class="ranged-data-picker__title">${this.translations.CHOOSE_DATE}</div>
            </div> 
            <div class="ranged-data-picker__body">
                <div class="ranged-data-picker__left" data-cy="ranged-dp-from-block">
                    <span>${this.translations.FROM_DATE}</span>
                    <div class="ranged-data-picker__start"></div>
                </div>
                <div class="ranged-data-picker__right" data-cy="ranged-dp-to-block">
                    <span>${this.translations.TO_DATE}</span>
                    <div class="ranged-data-picker__end"></div>
                </div>
            </div>
                <div class="ranged-data-picker__footer btn-wrap btn-wrap--end">
                    <button type="submit" class="button_regular button_regular_secondary cancel-range-js" data-cy="ranged-dp-clear-btn">${this.translations.CLEAR}</button>
                    <button type="submit" class="button_regular set-range-js" data-cy="ranged-dp-set-btn">${this.translations.SELECT}</button>
                </div>
            </div>
        </div>`;
    }

    // setToday() {
    //     const
    //         startDate = new Date(),
    //         endDate = new Date();
    //
    //     this.setDateField(startDate, endDate);
    //     this.close();
    // }

    setRange() {
        let
            startDate = this.startDatePicker.datepicker('getDate'),
            endDate = this.endDatePicker.datepicker('getDate');

        if (!startDate || !endDate) {
            return;
        }

        if (new Date(startDate) > new Date(endDate)) {
            return;
        }

        this.setDateField(startDate, endDate);

        this.close();
    }


    cancel() {
        this.startDatePicker.datepicker('setDate', null);
        this.endDatePicker.datepicker('setDate', null);

        this.setDateField(null, null);

        this.close()
    }

    close() {
        this.rangedPicker.classList.remove('active');
    }

    setDateField(startDate, endDate) {
        let
            startMonth, endMonth, startDay, endDay;

        this.startDateField.value = '';
        this.endDateField.value = '';
        this.rangeField.value = '';

        if (startDate && endDate) {
            startMonth = startDate.getMonth() + 1;
            endMonth = endDate.getMonth() + 1;

            if (startMonth < 10) {
                startMonth = '0' + startMonth;
            }

            if (endMonth < 10) {
                endMonth = '0' + endMonth;
            }

            startDay = startDate.getDate();
            endDay = endDate.getDate();

            if (startDay < 10) {
                startDay = '0' + startDay;
            }

            if (endDay < 10) {
                endDay = '0' + endDay;
            }

            this.startDateField.value = startDate.getFullYear() + '-' + startMonth + '-' + startDate.getDate();
            this.endDateField.value = endDate.getFullYear() + '-' + endMonth   + '-' + endDate.getDate();

            this.rangeField.value = startDay + '.' + startMonth + '.' + startDate.getFullYear() + ' - ' +
            endDay + '.' + endMonth + '.' + endDate.getFullYear();
        }

        this.startDateField.dispatchEvent(new Event('change'));
        this.endDateField.dispatchEvent(new Event('change'));
        this.rangeField.dispatchEvent(new Event('change'));
    }
}

export default (context = document) => {
    const pickers = Array.from(context.querySelectorAll('[data-component="ranged-date-picker"]'));
    for (const picker of pickers) {
        new RangedDataPicker(picker);
    }
}
