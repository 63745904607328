import {preventEvent, tryParseJson} from '../../utils/etc';

export class ExtendedDatePicker {
  constructor(wrapper) {
    this.date = new Date();
    this.preview = wrapper.querySelector('[data-role="preview"]');
    this.input = wrapper.querySelector('[data-role="data-input"]');
    this.dateParams = tryParseJson(wrapper.dataset.dateParams, {});
    this.picker = this.generatePicker();

    this.previewClickHandler = this.previewClickHandler.bind(this);

    this.preview.addEventListener('click', this.previewClickHandler);

    requestAnimationFrame(() => {
      wrapper.appendChild(this.picker);
    });
  }

  generatePicker() {
    const wrapperDiv = document.createElement('div');
    let datePickerParams = {};

    wrapperDiv.classList.add('wrap-calendar');
    wrapperDiv.style.height = 'unset';
    wrapperDiv.style.zIndex = 1

    const headingDiv = document.createElement('div');
    const headingTitle = document.createElement('h6');
    const headingToday = document.createElement('button');

    const { today, chooseDate } = window.__moni.translations.general;
    headingToday.classList.add('text_small', 'wrap-calendar__heading-today');
    headingTitle.classList.add('text_regular', 'wrap-calendar__heading-title');
    headingToday.innerHTML = today;
    headingTitle.innerHTML = chooseDate;
    headingDiv.classList.add('wrap-calendar__heading');
    headingDiv.appendChild(headingTitle);
    headingDiv.appendChild(headingToday);
    wrapperDiv.insertBefore(headingDiv, wrapperDiv.firstChild);

    headingToday.addEventListener('click', this.pickerSelectTodayHandler.bind(this));

    const pickerDropdown = document.createElement('div');
    wrapperDiv.appendChild(pickerDropdown);

    if (this.dateParams['maxDate'] !== false) {
      datePickerParams['maxDate'] = (this.dateParams['maxDate']) ? this.dateParams['maxDate'] : 'now';
    }

    if (typeof this.dateParams['minDate'] !== 'undefined') {
      datePickerParams['minDate'] = this.dateParams['minDate'];
    }

    datePickerParams['yearRange'] = this.dateParams['yearRange'] ? this.dateParams['yearRange'] : `${(new Date()).getFullYear() - 150}:now`;
    datePickerParams['changeYear'] = (typeof datePickerParams['changeYear'] !== 'undefined') ? datePickerParams['changeYear'] : true;
    datePickerParams.onSelect = this.datePickerSelectDateHandler.bind(this);
    datePickerParams.onChangeMonthYear = this.pickerMonthYearChangeHandler.bind(this);

    this.$picker = createSingleDatePicker(pickerDropdown, datePickerParams);

    const btnWrap = document.createElement('div');
    const btnClose = document.createElement('button');
    btnClose.addEventListener('click', this.previewClickHandler.bind(this));

    const btnConfirm = document.createElement('button');
    btnConfirm.addEventListener('click', this.confirmBtnClickHandler.bind(this));
    wrapperDiv.appendChild(btnWrap);

    btnWrap.classList.add('btn-wrap', 'btn-wrap--dp-mt', 'btn-wrap--fill');
    btnWrap.appendChild(btnClose);
    btnWrap.appendChild(btnConfirm);



    btnConfirm.classList.add('button_regular');
    btnClose.classList.add('button_regular',  'button_regular_secondary');

    const {choose, close} = window.__moni.translations.buttons;
    btnConfirm.innerHTML = choose;
    btnClose.innerHTML = close;

    return wrapperDiv;
  }

  pickerMonthYearChangeHandler(year, month, inst) {
    if (inst.currentYear !== inst.drawYear || inst.currentMonth !== inst.drawMonth) {
      this.date = new Date(year, month - 1, inst.selectedDay);
    }
  }
  pickerSelectTodayHandler(event) {
    preventEvent(event);
    const today = new Date();
    const monthNum = today.getMonth();
    const day = today.getDate();
    const year = today.getFullYear();
    const value = ("0" + day).slice(-2) + "." + ("0"+(monthNum + 1)).slice(-2) + "." + year;
    this.input.value = value;
    this.preview.value = value;
    this.$picker.datepicker('setDate', today);
    this.picker.classList.toggle('active');

  }

  datePickerSelectDateHandler(_, inst) {
    this.date.setFullYear(inst.selectedYear);
    this.date.setMonth(inst.selectedMonth);
    this.date.setDate(inst.selectedDay);
  }

  confirmBtnClickHandler(event) {
    preventEvent(event);

    const monthNum = this.date.getMonth();
    const day = this.date.getDate();
    const year = this.date.getFullYear();
    const value = ("0" + day).slice(-2) + "." + ("0"+(monthNum + 1)).slice(-2) + "." + year;
    this.input.value = value;
    this.preview.value = value;

    this.picker.classList.remove('active');
  }

  previewClickHandler(event) {
    preventEvent(event);

    this.picker.classList.toggle('active');
  }
}

export default (context = document) => {
  const pickers = Array.from(context.querySelectorAll('[data-component="extended-date-picker"]'));
  for (const picker of pickers) {
    new ExtendedDatePicker(picker);
  }
}
