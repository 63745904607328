export class RippleEffect {
    constructor(wrapper) {
        wrapper.addEventListener('click', this.handleRippleEffect);
    }

    handleRippleEffect(e) {
        // get wrapper pos and size
        let rect = this.getBoundingClientRect();
        let posX = rect.left + document.body.scrollLeft;
        let posY = rect.left.top + document.body.scrollTop;
        let width = parseFloat(getComputedStyle(this, null).width.replace("px", ""));
        let height =  parseFloat(getComputedStyle(this, null).height.replace("px", ""));
        //create ripple element
        const ripple = document.createElement("span");
        ripple.classList.add('ripple');

        //remove old one if exist
        if(!!this.querySelector('.ripple')) {
            this.removeChild(this.querySelector('.ripple'));
        }
        
        //prepend new one 
        this.prepend(ripple);
        
        // Make it round!
        if(width >= height) {
            width = height;
        } else {
            width = height;
        }

        // Get the center of the element
        let x = e.pageX - posX - width / 2;
        let y = e.pageY - posY - width / 2;

        // add ripple styles
        ripple.style.width = width + 'px';
        ripple.style.height = height + 'px';
        ripple.style.top = y + 'px';
        ripple.style.left = x + 'px';
        ripple.classList.add('rippleEffect');
    }
}

export default (context = document) => {
    const pickers = Array.from(context.querySelectorAll('.button, .button_regular, .button_small, .button_demi, .button_medium, .one-news__tags-tag'));
    for (const picker of pickers) {
        new RippleEffect(picker);
    }
}