import {findAll} from "../utils/dom";
import {preventEvent} from "../utils/etc";

class ActionButton {
    constructor (button, eventBus) {
        const {url, action} = button.dataset;

        this.url = url;
        this.action = action;
        this.eventBus = eventBus;

        button.addEventListener('click', this.btnClickHandler.bind(this));
    }

    btnClickHandler (event) {
        preventEvent(event);

        fetch(this.url, {
            method: event.target.dataset.method || 'GET'
        })
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(response.text());
                }

                return response.json();
            })
            .then(response => {
                const listeners = this.eventBus.get(this.action);
                if (!listeners || listeners.length < 1) {
                    return false;
                }

                for (const listener of listeners) {
                    listener(response);
                }
            });
    }
}

export default (context = document) => {
    const events = new Map();
    const buttons = findAll(context, '[data-component="action-button"]');
    for (const button of buttons) {
        const {action} = button.dataset;
        if (!events.has(`${action}`)) {
            events.set(action, []);
        }

        new ActionButton(button, events);
    }

    return events;
};