import {listenAllElements} from '../../utils/dom';
import Event from "../event/event";
import {EventBus} from "../event/event-bus";
export const EVENT_MODAL_OPEN = 'EVENT_MODAL_OPEN';
export const EVENT_MODAL_CLOSE = 'EVENT_MODAL_CLOSE';

export class SideModal extends EventBus {
  constructor(wrapper) {
    super(false);
    this.wrapper = wrapper;

    this.close = this.close.bind(this);

    listenAllElements(
        wrapper,
        '[data-role="close-modal-btn"]',
        'click',
        this.close,
    );

    wrapper.addEventListener('mousedown', this.wrapperClickHandler.bind(this));
  }

  wrapperClickHandler({target}) {
    if (target === this.wrapper) {
      this.close();
    }
  }

  open() {
    return Promise.resolve().then(() => requestAnimationFrame(() => {
      this.wrapper.classList.add('active');
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    })).then(() => {
      const editEvent = new Event(EVENT_MODAL_OPEN, this);
      this.dispatch(EVENT_MODAL_OPEN, editEvent);
    });
  }

  close() {
    return Promise.resolve().then(() => requestAnimationFrame(() => {
      this.wrapper.classList.remove('active');
      document.body.style.overflow = '';
      document.body.style.width = '';
    })).then(() => {
      const editEvent = new Event(EVENT_MODAL_CLOSE, this);
      this.dispatch(EVENT_MODAL_CLOSE, editEvent);
    });
  }
}