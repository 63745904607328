import {findAll} from "../utils/dom";

class CardNumber {
    constructor(wrapper) {
        this.inputs = findAll(wrapper, '[data-role="input"]');
        this.inputHandler = this.inputHandler.bind(this);

        let index = 0;
        for (const input of this.inputs) {
            input.dataset.index = index++;

            input.addEventListener('input', this.inputHandler);
        }
    }

    inputHandler({target}) {
        const length = `${target.value}`.length;
        const index = +target.dataset.index;

        if (length < 1 && this.inputs[index-1]) {
            this.inputs[index-1].focus();
        } else if (length === +target.maxLength && this.inputs[index+1]) {
            this.inputs[index+1].focus();
        }
    }
}

export default (context = document) => {
    const components = findAll(context, '[data-component="card-number"]');
    for (const component of components) {
        new CardNumber(component);
    }
};