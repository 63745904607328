import {findAll} from '../utils/dom';
import {objectToFormData, preventEvent} from '../utils/etc';

const COMPONENT_TYPE = 'image-uploader';

const defaultConfig = {
  updatePath: '',
  deletePath: '',
  isEmpty: true,
  mode: 'image', // image | background
  emptyValue: '',
  requestParams: {},
};

class ImageUploader {
  get fileReader() {
    if (!this._fileReader) {
      this._fileReader = new FileReader();
      this._fileReader.addEventListener('loadend', this.fileReaderLoadEnd);
    }

    return this._fileReader;
  }

  constructor(wrapper) {
    this.id = wrapper.dataset.id;
    this.wrapper = wrapper;
    this.isLoading = false;
    this.config = {
      ...defaultConfig,
      ...(window.__moni.components.imageUploader[this.id] || {}),
    };

    this.input = wrapper.querySelector('[data-role="input"]');
    this.addBtn = wrapper.querySelector('[data-button="add-btn"]');
    this.updateBtn = wrapper.querySelector('[data-button="update-btn"]');
    this.removeBtn = wrapper.querySelector('[data-button="remove-btn"]');
    this.preview = this.config.mode === 'image' ? wrapper.querySelector('[data-role="preview"]') : wrapper;

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.fileReaderLoadEnd = this.fileReaderLoadEnd.bind(this);
    this.updateBtnClickHandler = this.updateBtnClickHandler.bind(this);
    this.removeBtnClickHandler = this.removeBtnClickHandler.bind(this);

    this.input.addEventListener('change', this.inputChangeHandler);
    this.addBtn.addEventListener('click', this.updateBtnClickHandler);
    this.updateBtn.addEventListener('click', this.updateBtnClickHandler);
    this.removeBtn.addEventListener('click', this.removeBtnClickHandler);

    this.setLoading(false);
    if (this.config.isEmpty) {
      this.showAddMenu();
    } else {
      this.showEditMenu();
    }
  }
  
  setLoading(loading) {
    this.isLoading = !!loading;
    requestAnimationFrame(() => {
      if (this.isLoading) {
        this.wrapper.style.cursor = 'wait';
      } else {
        this.wrapper.style.cursor = '';
      }
    });
  }

  showEditMenu() {
    requestAnimationFrame(() => {
      this.addBtn.style.display = 'none';
      this.updateBtn.style.display = '';
      this.removeBtn.style.display = '';
    });
  }

  showAddMenu() {
    requestAnimationFrame(() => {
      this.addBtn.style.display = '';
      this.updateBtn.style.display = 'none';
      this.removeBtn.style.display = 'none';
    });
  }

  updateBtnClickHandler(event) {
    preventEvent(event);

    if (this.isLoading) {
      return;
    }

    this.input.click();
  }

  removeBtnClickHandler(event) {
    preventEvent(event);

    if (this.isLoading) {
      return;
    }

    this.setLoading(true);

    const formData = objectToFormData(this.config.requestParams);

    fetch(this.config.deletePath, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }).then((response) => {
      if (response.ok) {
        return true;
      }

      throw new Error(response);
    })
    .then(() => {
      this.showAddMenu();
      this.setPreviewValue(this.config.emptyValue);
    }).catch(() => {
      console.error('can not remove image')
    }).finally(() => {
      this.setLoading(false);
    });
  }

  inputChangeHandler() {
    const fileToUpload = this.input.files[0];
    if (!fileToUpload) {
      return;
    }

    this.setLoading(true);

    const formData = objectToFormData(this.config.requestParams);
    formData.append('file', fileToUpload);

    fetch(this.config.updatePath, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }).then((response) => {
      if (response.ok) {
        return true;
      }

      throw new Error(response);
    })
    .then(() => {
      this.showEditMenu();
      this.fileReader.readAsDataURL(fileToUpload);
    }).catch(() => {
      console.error('can not remove image')
    }).finally(() => {
      this.setLoading(false);
    });
  }

  fileReaderLoadEnd() {
    const value = this.config.mode === 'image'
        ? this.fileReader.result
        : `url('${this.fileReader.result}')`;
    this.setPreviewValue(value);
  }

  setPreviewValue(preview) {
    if (this.config.mode === 'image') {
      this.preview.src = preview;
    } else {
      this.preview.style.background = preview;
    }
  }
}

export default (context) => {
  const components = findAll(context, `[data-component="${COMPONENT_TYPE}"]`);
  for (const component of components) {
    new ImageUploader(component);
  }
};