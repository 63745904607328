import {findAll, listenElement} from "../utils/dom";
import {preventEvent} from "../utils/etc";

class FileField {
    constructor (wrapper) {
        this.fileInput = wrapper.querySelector('[data-role="file-input"]');
        this.nameWrapper = wrapper.querySelector('[data-role="name"]');

        this.fileInput.addEventListener('change', this.fileChangeHandler.bind(this));

        listenElement(
            wrapper,
            '[data-role="add-btn"]',
            'click',
            this.addBtnClickHandler.bind(this)
        );


    }

    fileChangeHandler (event) {
        preventEvent(event);

        const files = this.fileInput.files;
        if (files.length < 1) {
            return false;
        }

        const names = [].map.call(files, file => file.name);

        requestAnimationFrame(() => {
            this.nameWrapper.innerHTML = names.join(', ');
        });
    }

    addBtnClickHandler (event) {
        preventEvent(event);

        this.fileInput.click();
    }
}

export default (context = document) => {
    const inputs = findAll(context, '[data-component="file-field"]');
    for (const input of inputs) {
        new FileField(input);
    }
};