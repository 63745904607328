export class Modal {
    constructor(wrapper) {
        this._backdrop = null;
        this.isOpen = false;
        this.wrapper = wrapper;
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    get backdrop() {
        if (!this._backdrop) {
            this._backdrop = document.createElement('div');
            this.backdrop.classList.add('modal-backdrop', 'fade');
        }

        return this._backdrop;
    }

    open() {
        if (this.isOpen) {
            return;
        }

        const wrapper = document.body;
        this.isOpen = true;
        this.wrapper.addEventListener('click', this.close);

        requestAnimationFrame(() => {
            wrapper.appendChild(this.backdrop);
            wrapper.appendChild(this.wrapper);
            this.wrapper.style.display = 'block';

            this.backdrop.classList.add('show');
            wrapper.classList.add('modal-open');
            this.wrapper.classList.add('show');
        });
    }

    close() {
        if (!this.isOpen) {
            return;
        }

        const wrapper = document.body;
        this.isOpen = false;
        this.wrapper.removeEventListener('click', this.close);

        requestAnimationFrame(() => {
            wrapper.removeChild(this.backdrop);
            wrapper.removeChild(this.wrapper);
            this.wrapper.style.display = '';

            this.backdrop.classList.remove('show');
            wrapper.classList.remove('modal-open');
            this.wrapper.classList.remove('show');
        });
    }
}
