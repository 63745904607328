import { SideModal } from '../components/modal/side-modal';
import { preventEvent } from '../utils/etc';
import { listenAllElements, listenElement } from '../utils/dom';

class Menu {
  constructor(wrapper) {
    this.sideModal = new SideModal(wrapper);
    this.menuSections = this.getMenuSections(wrapper);
    this.activeSection = null;
    this.loginButton = document.querySelector('[data-menu-section-open="login"]');
    this.registerButton = document.querySelector('.header-dropdown-nav__register-btn');

    listenAllElements(document.body, '[data-menu-section-open]', 'click', this.actionBtnClickHandler.bind(this));

    listenElement(document.body, '[data-menu-btn="close"]', 'click', this.closeBtnClickHandler.bind(this));

    const url = new URL(document.location.href);
    if (url.searchParams.get('showModal') === window.__moni.const.showModal.TYPE_LOGIN) {
      this.loginButton.dispatchEvent(new Event('click'));
    }
  }

  closeBtnClickHandler(event) {
    preventEvent(event);

    this.sideModal.close();
  }

  actionBtnClickHandler(event) {
    preventEvent(event);
    const key = event.target.dataset.menuSectionOpen;
    this.activateSection(key);
    this.sideModal.open();
    switch (this.activeSection.dataset.menuSection) {
      case 'login':
        this.registerButton.classList.remove('active');
        this.loginButton.classList.add('active');
        break;
      case 'register':
        this.registerButton.classList.add('active');
        this.loginButton.classList.remove('active');
        break;
      default:
        this.registerButton.classList.remove('active');
        this.loginButton.classList.remove('active');
        break;
    }
  }

  getMenuSections(wrapper) {
    const sections = {};
    for (const section of [...wrapper.querySelectorAll('[data-menu-section]')]) {
      sections[section.dataset.menuSection] = section;
    }

    return sections;
  }

  activateSection(key) {
    const newSection = this.menuSections[key];
    if (!newSection) {
      return;
    }

    if (this.activeSection) {
      this.activeSection.classList.remove('active');
    }

    this.activeSection = newSection;
    this.activeSection.classList.add('active');
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const menuWrapper = document.querySelector('#login-register-side-modal');
  if (menuWrapper) {
    new Menu(menuWrapper);
  }
});
