export const getGeoCoder = () => {
    return new Promise((resolve, reject) => {
        if (!window.google) {
            reject(new Error('Error loading google library'));
        } else {
            resolve(new window.google.maps.Geocoder());
        }
    });
};

export const geocode = options => {
    return geocoder => {
        return new Promise((resolve, reject) => {
            geocoder.geocode(options, (results, status) => {
                if (status === 'OK') {
                    resolve(results);
                } else {
                    reject(new Error(`Geolocation request throws ${status}`));
                }
            });
        });
    }
};

export const getLocationByAddress = (address) => {
    return getGeoCoder()
        .then(geocode({
            address
        }))
        .then(results => {
            return results[0].geometry.location;
        });
};