import { findAll, importTemplate } from '../../utils/dom';
import { debounce, getComponentConfig, mapTemplate, preventEvent } from '../../utils/etc';
import {buildQuery} from '../../utils/url';
import {EventBus} from '../event/event-bus';
import Event from '../event/event';

const defaultConfig = {
    method: 'GET',
    url: '',
    itemsCount: 3,
    queryParamKey: 'q',
    queryKey: '',
    titleKey: 'title',
    valueKey: 'id',
    sourceType: 'list',
    list: [],
    itemsCountKey: 'items_per_page',
    templateSupport: false,
    initialData: [],
    dataMapping: {},
    resultDataMapping: {},
    exclude: []
};

const COMPONENT_TYPE = 'autocomplete';

export const EVENT_TYPE_UPDATE_VALUE = 'update_value';

export class Autocomplete extends EventBus {
    constructor(wrapper) {
        super(false);

        const id = `${wrapper.dataset.id}`;
        this.config = getComponentConfig(COMPONENT_TYPE, id, defaultConfig);
        this.initialData = null;
        this.isLoading = false;
        this.resultMap = {};
        this.template = wrapper.querySelector('[data-role="template"]')

        this.listClickHandler = this.listClickHandler.bind(this);
        this.inputFocusHandler = this.inputFocusHandler.bind(this);
        this.inputInputHandler = this.inputInputHandler.bind(this);
        this.documentClickHandler = this.documentClickHandler.bind(this);

        this.input = wrapper.querySelector('[data-role="input"]');
        this.input.addEventListener('input', debounce(this.inputInputHandler, 350));
        this.input.addEventListener('focus', this.inputFocusHandler);
        this.input.form && this.input.form.addEventListener('reset', this.formResetHandler.bind(this));

        this.resultList = wrapper.querySelector('[data-role="result-list"]');
        this.resultList.addEventListener('click', this.listClickHandler);
    }

    documentClickHandler() {
        requestAnimationFrame(() => {
            this.resultList.innerHTML = '';
        });

        document.removeEventListener('click', this.documentClickHandler);
    }

    inputFocusHandler() {
        if (this.initialData) {
            this.updateResultList(this.initialData);

            return;
        }

        this.inputInputHandler();
    }

    inputInputHandler() {
        const search = this.input.value;

        switch (this.config.sourceType) {
            case 'list':
                this.searchWithList(search);
                break;
            case 'url':
                this.searchWithAjax(search);
                break;
            default:
                console.error(`Undefined autocomplete driver ${this.config.sourceType}`)
        }
    }

    listClickHandler(event) {
        preventEvent(event);

        let target = event.target;
        const {action, marker} = target.dataset;

        if (action !== 'updateValue') {
            return;
        }

        if(marker !== 'wrapper') {
            target = event.target.closest('[data-marker="wrapper"]');
        }

        if (target) {
            this.updateValue(target);
            this.clearList();
        }
    }

    searchWithAjax(search) {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const query = {
            [this.config.queryParamKey]: search,
            [this.config.itemsCountKey]: this.config.itemsCount,
            exclude: this.config.exclude,
        };

        fetch(buildQuery(this.config.url, query, this.config.queryKey), {
            method: this.config.method,
            credentials: 'include',
        }).then((response) => {
            if (!response.ok) {
                this.isLoading = false;
                return Promise.reject(response.text());
            }

            return response.json();
        }).then((json) => {
            this.updateResultList(json);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    searchWithList(search) {
        const {titleKey} = this.config;

        let result = [];
        for (const item of this.config.list) {
            const title = item[titleKey];
            if (title.indexOf(search) < 0) {
                continue;
            }

            result.push(item);
        }

        this.updateResultList(result);
    }

    updateValue(item) {
        const value = item.dataset.value;
        this.input.value = value;

        const event = new Event(EVENT_TYPE_UPDATE_VALUE, this);
        event.extra.set('value', value);
        event.extra.set('title', item.innerHTML);
        event.extra.set('item', this.resultMap[value]);

        this.dispatch(EVENT_TYPE_UPDATE_VALUE, event);
    }

    clearList() {
        this.resultMap = {};
        requestAnimationFrame(() => {
            this.resultList.innerHTML = '';
        });
    }

    updateResultList(result) {
        if (this.initialData) {
            this.initialData = result;
        }

        const {titleKey, valueKey} = this.config;

        const fragment = document.createDocumentFragment();
        for (const item of result) {
            this.resultMap[item[valueKey]] = item;

            if (this.config.templateSupport) {
                const template = importTemplate(this.template);
                mapTemplate(template, item, this.config.dataMapping);
                fragment.appendChild(template);
            } else {
                const title = item[titleKey];
                const div = document.createElement('div');
                div.dataset.value = item[valueKey];
                div.dataset.action = 'updateValue';
                div.className = 'dropdown-item';
                div.innerHTML = title;

                fragment.appendChild(div);
            }
        }

        this.renderListContent(fragment);

        document.addEventListener('click', this.documentClickHandler);
    }

    renderListContent(content) {
        requestAnimationFrame(() => {
            this.resultList.innerHTML = '';
            this.resultList.appendChild(content);
        });
    }

    formResetHandler() {
        requestAnimationFrame(() => {
            this.input.value = '';
            this.resultList.innerHTML = '';
            this.resultMap = {};
        });
    }
}

export default (context = document) => {
    const items = findAll(context, `[data-component="${COMPONENT_TYPE}"]`);
    for (const item of items) {
        new Autocomplete(item);
    }
};
