import {findAll} from "../utils/dom";
import initActionButtons from './action-button';

class CompanyExpertItem {
    constructor (wrapper) {
        this.wrapper = wrapper;

        const btnEventBus = initActionButtons(wrapper);
        (btnEventBus.get('remove') || []).push(this.removeBtnClickHandler.bind(this));
    }

    removeBtnClickHandler() {
        requestAnimationFrame(() => {
            this.wrapper.parentNode.removeChild(this.wrapper);
        });
    }
}

export default (context = document) => {
    const items = findAll(context,'[data-component="company-expert-item"]');
    for (const item of items) {
        new CompanyExpertItem(item);
    }
}