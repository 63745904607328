import {findAll} from '../utils/dom';
import {preventEvent} from '../utils/etc';
import {
  startLoading,
  stopLoading,
} from '../utils/mixins/loading';

class NotificationItem {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.id = +wrapper.dataset.id;
    this.isLoading = false;
    this.icon = wrapper.querySelector('[data-role="check"]');
    this.removeBtn = wrapper.querySelector('[data-role="remove-btn"]');
    this.changeStatusBtn = wrapper.querySelector('[data-role="change-status-btn"]');
    this.statusChecked = wrapper.querySelector('[data-role="checked-icon"]')

    this.removeBtn.addEventListener('click', this.removeBtnClickHandler.bind(this));
    this.changeStatusBtn.addEventListener('click', this.setAsSeenBtnClickHandler.bind(this));
    wrapper.addEventListener('updatestatus', this.updateStatusHandler.bind(this));

    this.updateStatus(+wrapper.dataset.seen);

  }

  updateStatusHandler({detail: {status}}) {
    this.updateStatus(+status);
  }

  

  removeBtnClickHandler(event) {
    preventEvent(event);
    if (this.isLoading) {
      return;
    }
    startLoading(this.removeBtn);

    const data = new FormData();
    data.append('id', this.id);

    fetch(window.__moni.urls.notification.remove, {
      method: 'POST',
      body: data,
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }

      return Promise.reject(response.text());
    }).then(() => {
      requestAnimationFrame(() => {
        this.wrapper.remove()
      });
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      stopLoading(this.removeBtn);
    });
  }

  setAsSeenBtnClickHandler(event) {
    preventEvent(event);
    if (this.isLoading) {
      return;
    }
    startLoading(this.changeStatusBtn);

    const data = new FormData();
    data.append('ids', this.id);
    data.append('seen', this.wrapper.dataset.seen);

    fetch(window.__moni.urls.notification.setSeen, {
      method: 'POST',
      body: data,
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }

      return Promise.reject(response.text());
    }).then(({ status }) => {
      this.updateStatus(+status);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      stopLoading(this.changeStatusBtn);
    });
  }

  updateStatus(status) {
    status = +status;

    this.wrapper.dataset.seen = status;
    if (status) {
      this.icon.classList.add('material-icons-outlined');
      this.icon.classList.remove('material-icons');
      this.statusChecked.classList.add('checked-icon')
      this.statusChecked.classList.remove('unchecked-icon')
      this.changeStatusBtn.classList.add('checked-color')
      this.wrapper.classList.remove('new');
    } else {
      this.icon.classList.add('material-icons');
      this.statusChecked.classList.add('unchecked-icon')
      this.statusChecked.classList.remove('checked-icon')
      this.icon.classList.remove('material-icons-outlined');
      this.changeStatusBtn.classList.remove('checked-color')
      this.wrapper.classList.add('new');
    }
  }
}

export default (context = document) => {
  const components = findAll(context, '[data-component="notification-item"]');
  for (const component of components) {
    new NotificationItem(component);
  }
};