import {findAll, listenAllElements, listenElement} from "../utils/dom";
import {attachIdToUrl, preventEvent} from "../utils/etc";

class UserRequestItem {
    constructor(wrapper) {
        this.wrapper = wrapper;

        this.statusAccepted = wrapper.querySelector('[data-role="status-accepted"]');
        this.statusDeleted = wrapper.querySelector('[data-role="status-removed"]');
        this.btnBlock = wrapper.querySelector('[data-role="btn-block"]');

        listenAllElements(wrapper, '[data-role="btn-decline"]', 'click', this.declineBtnClickHandler.bind(this));
        listenElement(wrapper, '[data-role="btn-accept"]', 'click', this.acceptBtnClickHandler.bind(this));

        this.remove = this.remove.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.showAcceptedStatus = this.showAcceptedStatus.bind(this);
        this.showDeletedStatus = this.showDeletedStatus.bind(this);
    }

    declineBtnClickHandler(event) {
        preventEvent(event);

        this.sendRequest(event.target.dataset.url)
            .then(json => {
                this.showDeletedStatus();
                this.remove();
            })
            .catch(err => console.error(err));
    }

    acceptBtnClickHandler(event) {
        preventEvent(event);

        this.sendRequest(event.target.dataset.url)
            .then(json => {
                this.showAcceptedStatus();
                this.remove();
            })
            .catch(err => console.error(err));
    }

    sendRequest(url) {
        return fetch(url, {
            method: 'POST',
            body: this.data
        })
            .then(resp => {
                if (resp.status !== 200) {
                    return Promise.reject(resp.text());
                }

                return resp.json();
            });
    }

    remove() {
        setTimeout(() => requestAnimationFrame(() => {
            if (this.wrapper.parentNode) {
                this.wrapper.parentNode.removeChild(this.wrapper);
            }
        }), 1500);
        window.location.reload();
    }

    showAcceptedStatus() {
        requestAnimationFrame(() => {
            this.btnBlock.style.display = 'none';
            this.statusAccepted.style.display = '';
        });
    }

    showDeletedStatus() {
        requestAnimationFrame(() => {
            this.btnBlock.style.display = 'none';
            this.statusDeleted.style.display = '';
        });
    }
}

export default (context = document) => {
    const items = findAll(context, '[data-component="user-company-request-item"]');
    for (const item of items) {
        new UserRequestItem(item);
    }
};