import {findAll} from "../utils/dom";
import {preventEvent} from "../utils/etc";

class Accordion {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.accordion = wrapper.querySelector('[data-component="accordion-header"]');
        this.accordionBody = wrapper.querySelector('[data-component="accordion-body"]');
        this.accordion.addEventListener('click', this.accordionClick.bind(this));
        this.isActive = true;
    }

    accordionClick(target) {
        preventEvent(target);
        if(this.isActive) {
            this.showAccordion()
        } else {
            this.hideAccordion();
        }
    }

    showAccordion() {
        requestAnimationFrame(() => {
            this.accordionBody.style.maxHeight = this.accordionBody.scrollHeight + 'px';
            this.accordion.classList.add('active');
            this.isActive = false;
        });
    }

    hideAccordion() {
        requestAnimationFrame(() => {
            this.accordionBody.style.maxHeight = 0;
            this.accordion.classList.remove('active');
            this.isActive = true;
        });
    }
}

export default (context = document) => {
    const accordions = findAll(context, '[data-component="accordion"]');
    for (const accordion of accordions) {
        new Accordion(accordion);
    }
};