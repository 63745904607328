'use strict';

import $ from 'jquery';
import { createDatePicker, createSingleDatePicker } from './date-pickers';

var notificationAlert = function (type, title, text, url = null) {
  window.__moni.services.notification.show({
    // options
    title: title,
    body: text,
    url: url,
    type: type,
  });
};

var websocketSendMessage = function (
  websocketConnection,
  connectionType,
  accessToken,
  dialog,
  message,
  attachments,
  read,
  user,
  wallet,
  lockedSum,
  notificationId,
  seenNotification,
  showedNotification,
  removeNotification,
  dontAnswer,
) {
  if (websocketConnection.readyState == websocketConnection.OPEN) {
    if (notificationId == undefined) {
      notificationId = false;
    }

    if (seenNotification == undefined) {
      seenNotification = false;
    }

    if (showedNotification == undefined) {
      showedNotification = false;
    }

    if (removeNotification == undefined) {
      removeNotification = false;
    }

    if (dontAnswer == undefined) {
      dontAnswer = false;
    }

    websocketConnection.send(
      JSON.stringify({
        connectionType: connectionType, //type of send data
        access_token: accessToken, //user access token for identify
        dialog: dialog, //dialog identifier
        message: message, //dialog text message
        attachments: attachments, //dialog attachments(filename array)
        read: read, //read message request if not conference
        user: user, //user id
        wallet: wallet, // wallet sum
        lockedSum: lockedSum, // wallet lockedSum
        notificationId: notificationId, // notification id
        seenNotification: seenNotification, // mark notif as seen
        showedNotification: showedNotification, // if show notif in $.notify
        removeNotification: removeNotification, // delete notification
        dontAnswer: dontAnswer, // do not response in notif type
      }),
    );
  }
};

global.$ = $;
global.jQuery = $;
global.notificationAlert = notificationAlert;
global.websocketSendMessage = websocketSendMessage;
global.createDatePicker = createDatePicker;
global.createSingleDatePicker = createSingleDatePicker;
