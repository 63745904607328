import {findAll} from "../utils/dom";

class DatePicker {
    constructor(picker) {
        this.input = picker;
        this.$picker = createSingleDatePicker(picker, {
            changeYear: true,
            dateFormat: "dd MM yy",
            yearRange: `${(new Date()).getFullYear() - 150}:now`
        });
    }

    get isEnLocale () {
        return window.__moni.app.locale === 'en';
    }

    dateSelectedHandler(dateText) {
        const dateAsString = dateText;
        const newDateFormat = $.datepicker.parseDate('dd MM yy', dateAsString);
        dateNumber = newDateFormat.getDate() + "." + (newDateFormat.getMonth() + 1) + "." + newDateFormat.getFullYear();
        if (!this.isEnLocale) {
            const newDateAsString = dateAsString.split(' ');
            newDateAsString[1] = months[newDateFormat.getMonth()];
            $(this).val(newDateAsString.join(' '));
        }
    }

    getDate() {
        return this.$picker.datepicker('getDate');
    }
}

export default (context = document) => {
    const pickers = findAll(context, '[data-component="date-picker"]');
    for (const picker of pickers) {
        new DatePicker(picker);
    }
};