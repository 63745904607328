import {findAll, listenElement} from '../utils/dom'
import { debounce } from '../utils/etc'
import {getLocationByAddress} from "../utils/google/geocoder";


class Location {
    constructor (wrapper) {
        this.isLoading = false;
        this.marker = null;
        this.map = null;
        this.latInput = wrapper.querySelector('[data-role="latitude-input"]');
        this.lngInput = wrapper.querySelector('[data-role="longitude-input"]');
        this.autocomplete = listenElement(
            wrapper,
            '[data-role="autocomplete"]',
            'change',
            debounce(this.autocompleteChangeHandler.bind(this), 500)
        );
        this.initMap(wrapper);
    }
    visualisePlaceOnMap(lat, lng, label,  mapEl) {
        if (!this.marker) {
            this.marker = new google.maps.Marker({
                map: mapEl
            });
        }
        this.marker.setTitle(label);
        this.marker.setPosition({
            lat,
            lng
        });
        mapEl.setCenter({
            lat,
            lng
        });
    }
    getLocationByAdressOnMap(address, mapEl) {
        getLocationByAddress(address)
            .then(location => {
                const lat = location.lat();
                const lng = location.lng();

                this.latInput && (this.latInput.value = lat);
                this.lngInput && (this.lngInput.value = lng);

                this.visualisePlaceOnMap(lat, lng, address,  mapEl);
                this.isLoading = false;
            })
            .catch(err => {
                console.error(err);
                this.isLoading = false;
            });

    }
    autocompleteChangeHandler (event) {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        const address = `${event.target.value}`;
        this.getLocationByAdressOnMap(address, this.map);

    }
    initMap(wrapper) {
        this.map = new google.maps.Map(wrapper.querySelector('[data-role="map"]'), {
          center: {lat: -33.8688, lng: 151.2195},
          zoom: 13,
        });
        requestAnimationFrame(() => {
            wrapper.querySelector('[data-role="map"]').style.height = '450px';
        });
        var input = wrapper.querySelector('[data-role="autocomplete"]');
        var autocomplete = new google.maps.places.Autocomplete(input);

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo('bounds', this.map);

        // Set the data fields to return when the user selects a place.
        autocomplete.setFields(
            ['address_components', 'geometry', 'icon', 'name']);

        var infowindow = new google.maps.InfoWindow();
        var infowindowContent = document.getElementById('infowindow-content');
        infowindow.setContent(infowindowContent);
        
        this.visualisePlaceOnMap(
            +this.latInput.value,
            +this.lngInput.value,
            '',
            this.map
        );

        var marker = new google.maps.Marker({
          map: this.map,
          anchorPoint: new google.maps.Point(0, -29)
        });
        autocomplete.addListener('place_changed', () => {
          infowindow.close();
          marker.setVisible(false);
          var place = autocomplete.getPlace();
          if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
          } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(17);  // Why 17? Because it looks good.
          }
          marker.setPosition(place.geometry.location);
          marker.setVisible(true);

          var address = '';
          if (place.address_components) {
            address = [
              (place.address_components[0] && place.address_components[0].short_name || ''),
              (place.address_components[1] && place.address_components[1].short_name || ''),
              (place.address_components[2] && place.address_components[2].short_name || '')
            ].join(' ');
          }
          infowindowContent.children['place-icon'].src = place.icon;
          infowindowContent.children['place-name'].textContent = place.name;
          infowindowContent.children['place-address'].textContent = address;
          infowindow.open(this.map, marker);
        });
    }
    
}

export const initlocationFields = (context = document) => {
    for (const field of findAll(context, '[data-component="field-location"]')) {
        new Location(field);
    }
};

window.addEventListener('load', () => initlocationFields());