import './pages/menu';

$(document).on('ready', function () {
  const location = document.location;
  $('.lazy').lazy({
    effect: 'fadeIn',
    effectTime: 1000,
    threshold: 0,
  });

  $(document).on('click', '.closeModal-js', function (e) {
    e.preventDefault();
    $('.main-dropdown').removeClass('active');
    $('body').css({ overflow: '', 'padding-right': '0px', width: '100%', position: 'static' });
  });

  // svg4everybody({});
  $('.js-select2picker-multiple').select2({
    placeholder: function () {
      return $(this).data('placeholder');
    },
    language: location.href.indexOf('ru') != -1 ? 'ru' : location.href.indexOf('uk') != -1 ? 'uk' : '',
  });
  $('.js-select2picker').select2({
    placeholder: function () {
      return $(this).data('placeholder');
    },
    language: location.href.indexOf('ru') != -1 ? 'ru' : location.href.indexOf('uk') != -1 ? 'uk' : '',
  });
  $('.js-select2picker-no-search').select2({
    placeholder: function () {
      return $(this).data('placeholder');
    },
    minimumResultsForSearch: Infinity,
    language: location.href.indexOf('ru') != -1 ? 'ru' : location.href.indexOf('uk') != -1 ? 'uk' : '',
  });

  $('.js-select2picker-status').select2({
    placeholder: function () {
      return $(this).data('placeholder');
    },
    theme: 'default status-select-dropdown',
    language: location.href.indexOf('ru') != -1 ? 'ru' : location.href.indexOf('uk') != -1 ? 'uk' : '',
  });

  $('.js-select2picker-search').select2({
    placeholder: function () {
      return $(this).data('placeholder');
    },
    language: location.href.indexOf('ru') != -1 ? 'ru' : location.href.indexOf('uk') != -1 ? 'uk' : '',
  });

  (() => {
    $(document).on('click', function (e) {
      if ($(e.target).attr('data-name')) {
        $(this)
          .find(`div[data-result="${$(e.target).attr('data-name')}"]`)
          .addClass('active');
        $('body').css('overflow', 'hidden');
      } else if (
        $(e.target).closest('.main-dropdown-content').length === 0 &&
        $(e.target).closest('.main-dropdown').length > 0
      ) {
        $(this).find('div[data-result]').removeClass('active');
        $('.header-container__register-btn').removeClass('show').siblings('.auth-btn').addClass('show');
        $('body').css('overflow', '');
      }
    });

    $('.nav-btn').on('click', function (e) {
      $('.nav-dropdown').addClass('active').siblings().removeClass('active');
    });

    $('.forgot-password').on('click', function (e) {
      $('.forgot-password-block').addClass('active').siblings().removeClass('active');
    });

    $('.more, .less').on('click', function () {
      var parentElement = $(this).parent();
      if ($(this).hasClass('more')) {
        $(this).addClass('active');
        parentElement.find('.ol').css('height', '100%');
        parentElement.find('.less').addClass('active');
      } else {
        $(this).removeClass('active');
        parentElement.find('.more').css('height', '100%');
        parentElement.find('.ol').css('height', '20px');
        parentElement.find('.more').removeClass('active');
      }
    });
  })();

  // expanded description blocks on cabinet pages
  $('.cabinet-content-subheader').each((i, el) => {
    const subheaderMaxHeight = 100,
      subheader = $(el),
      showButton = subheader.find('.content-show-button'),
      wrapper = subheader.find('.cabinet-content-subheader__wrapper');

    if (wrapper.length > 0 && wrapper.height() > subheaderMaxHeight) {
      subheader.addClass('expand-block');

      const expert_block = document.querySelector("[data-role='experts-block']");
      const dropdown_block = document.querySelector("[data-role='dropdown-block']");
      const less_button = dropdown_block.querySelector('[data-role="less-button"]');
      const more_button = dropdown_block.querySelector('[data-role="more-button"]');

      expert_block
        ? showButton.on('click', e => {
          wrapper.toggleClass('opened');

          e.target.dataset.icon === 'expand_less'
            ? ((e.target.dataset.icon = 'expand_more'),
              less_button.classList.remove('button-hide'),
              more_button.classList.add('button-hide'),
              (less_button.disabled = false),
              dropdown_block.querySelector("[data-role='monitoring-block']").classList.remove('monitoring-block'),
              dropdown_block.querySelector("[data-role='monitoring-block']").classList.add('start-block'))
            : ((e.target.dataset.icon = 'expand_less'),
              less_button.classList.add('button-hide'),
              more_button.classList.remove('button-hide'),
              (less_button.disabled = true),
              dropdown_block.querySelector("[data-role='monitoring-block']").classList.add('monitoring-block'),
              dropdown_block.querySelector("[data-role='monitoring-block']").classList.remove('start-block'));
        })
        : showButton.on('click', e => {
          wrapper.toggleClass('opened');
          e.target.dataset.icon === 'expand_less'
            ? (e.target.dataset.icon = 'expand_more')
            : (e.target.dataset.icon = 'expand_less');
        });
    }
  });

  // screaning and monotoring js
  (() => {
    const maxHeightEl = 40,
      monitoringListWarnings = $('.monitoring-item ol');
    monitoringListWarnings.each((i, el) => {
      const ol = $(el);
      if (ol.height() > maxHeightEl) {
        ol.addClass('expand-enabled');
      }
    });
    monitoringListWarnings.filter('.expand-enabled').on('click', e => {
      const toggleClass = 'open',
        listItem = $(e.currentTarget);

      if (listItem.hasClass(toggleClass)) {
        listItem.removeClass(toggleClass);
      } else {
        monitoringListWarnings.removeClass(toggleClass);
        listItem.addClass(toggleClass);
      }
    });
  })();

  $('#company_material_imageFile').on('change', e => {
    let el = $(e.currentTarget),
      imagePath = el.val().replace(/.*(\/|\\)/, '');
    el.parent().find('.fileName-js').html(imagePath);
  });
});

$('.faq-item__heading').on('click', function (e) {
  if (!$(this).parent().hasClass('active')) {
    $('.faq-item .faq-item__desc').slideUp(300);
    $('.faq-item').removeClass('active');
    $(this).next('.faq-item__desc').slideDown(300);
    $(this).parent().addClass('active');
  } else if ($(this).parent().hasClass('active')) {
    $(this).parent().removeClass('active');
    $(this).next('.faq-item__desc').slideUp(300);
  }
});

$(document).on('click', '.context-menu', function (e) {
  if (!e.target.dataset.name) {
    e.stopPropagation();
  }
  $('.context-menu').not(this).removeClass('active');
  $(this).toggleClass('active');
});

$(document).on('click', function (e) {
  if (!e.target.dataset.name) {
    $('.context-menu').removeClass('active');
  }
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggleTool="tooltip"]').tooltip();
});

$('.profile-first-number').click(function () {
  const parent = $(this).parent().parent();
  parent.find('.profile-about__numbers-list').slideToggle(300);
  $(this).toggleClass('active');
});

$(function () {
  if (window.Modernizr && !Modernizr.inputtypes.date) {
    createDatePicker('input[type=date]');
  }
});
$('.more-info__title').on('click', function () {
  $(this).toggleClass('active');
  $('.more-info__more-text').toggleClass('active');
});

$('.company-profile__field-btn').on('click', function () {
  $(this).addClass('active');
});

// tablets benefits tab-pane centering
$(window).on('load', function () {
  if ($(window).width() > 768 && $(window).width() <= 1024) {
    const benefitsTabletCentering = (() => {
      $('#v-pills-tab a').on('shown.bs.tab', function (e) {
        var selectedTabId = e.target.id;
        var selectedTabPane = $(`[aria-labelledby="${selectedTabId}"]`);
        setTimeout(() => {
          $('html, body').animate(
            {
              scrollTop: $(selectedTabPane).offset().top,
            },
            300,
          );
        }, 300);
      });
    })();
  }
});

// Home page accordeon
$('#benefit > .benefit-container > .functions-nav__link').slice(0, 1).addClass('active');
$('#benefit .benefit-container .benefit-content').slice(1).hide();
$('#benefit > .benefit-container > a').on('click', function (e) {
  if ($(this).parent().has('.benefit-content')) {
    e.preventDefault();
  }
  const speed = 300;
  if (!$(this).hasClass('active')) {
    $('#benefit .benefit-container .benefit-content').slideUp(speed);
    $('#benefit .benefit-container a').removeClass('active');

    $(this).next('.benefit-content').slideDown(speed);
    $(this).addClass('active');
    setTimeout(() => {
      $('html, body').animate(
        {
          scrollTop: $(this).offset().top,
        },
        300,
      );
    }, 300);
  } else if ($(this).hasClass('active')) {
    $(this).removeClass('active');
    $(this).next('.benefit-content').slideUp(speed);
  }
});

//profileNavMenu on Mobile
const profileMenuLink = $('.cabinet-mobile-more');
const profileMobileMenu = $('.cabinet-mobile-menu');
const mobileMenuFunc = (menu, link) => {
  link.click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).toggleClass('active');
    menu.toggleClass('active');
    menu.slideToggle(300);
    $('.notification-services').toggleClass('active');
    $('body').toggleClass('active');
  });
  $(window).click(function (e) {
    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
      menu.removeClass('active').css('display', 'none');
      $('body').removeClass('active');
      $('.notification-services').removeClass('active');
    }
  });
};
mobileMenuFunc(profileMobileMenu, profileMenuLink);

//toggle password visibility
const showPassBtn = $('.show-pass');

const togglePasswordVisibility = toggleButton => {
  toggleButton.click(function () {
    const passwordField = $(this).parent().find('input');
    if (passwordField.attr('type') === 'password') {
      passwordField.attr('type', 'text');
      $(this).addClass('active');
    } else {
      passwordField.attr('type', 'password');
      $(this).removeClass('active');
    }
  });
};
togglePasswordVisibility(showPassBtn);

//toggle element visibility
const toggleScheduleButton = $('#toggle-expert-schedule');
const expertScheduleContent = $('.profile-sidebar-mobile');
const toggleElementVisibility = (elementButton, elementContent) => {
  elementButton.click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    elementContent.slideToggle(300);
  });
  elementContent.click(function () {
    $(this).slideToggle(300);
    elementButton.toggleClass('active');
  });
};
toggleElementVisibility(toggleScheduleButton, expertScheduleContent);

// scroll to top buttons
const scrollToTopHomeButton = $('#scrollToTopHomeButton');

const scrollToTop = anyScrollButton => {
  $(window).scroll(function () {
    if ($(window).scrollTop() > 800) {
      anyScrollButton.addClass('show');
    } else {
      anyScrollButton.removeClass('show');
    }
  });
  anyScrollButton.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
  });
};
scrollToTop(scrollToTopHomeButton);

// header search dropdown
const toggleHeaderSearch = ((
  elementButton = $('.header-search-button'),
  elementContent = $('.header-search-mobile'),
) => {
  elementButton.click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    elementContent.slideToggle(300);
  });
})();

(() => {
  let preloaderEl = document.querySelector('.before-load');
  window.addEventListener('load', function () {
    preloaderEl.classList.add('hidden');

    window.requestAnimationFrame(() => {
      preloaderEl.parentNode.removeChild(preloaderEl);
      document.body.classList.remove('active');
    });
  });
})();

// Redirect after social login/connect to profile
const urlParams = new URLSearchParams(window.location.search);
const action = urlParams.get('action');
const redirectTo = urlParams.get('redirectTo');
const social = urlParams.get('social');
const error = urlParams.get('error');
const errorCode = urlParams.get('errorCode');
if ((document.location.hash === '#_=_' || social) && action && redirectTo) {
  location.href = window.__moni.urls[action + redirectTo] + `?social=${social}&error=${error}&errorCode=${errorCode}`;
}
$('.archive-history__details').on('click', function (e) {
  var $this = $(this);
  var $collapseElement = $this.parent().next();

  if (!$this.hasClass('active')) {
    $collapseElement.show();
    $collapseElement.find('.temp-wrapper').slideDown(300);
    $this.addClass('active');
  } else {
    if ($collapseElement.find('.temp-wrapper').length > 0) {
      $collapseElement.find('.temp-wrapper').slideUp(300, function () {
        $(this).parent().parent().hide();
      });
    } else {
      $collapseElement
        .find('td')
        .first()
        .wrapInner('<div class="temp-wrapper"/>')
        .parent()
        .find('.temp-wrapper')
        .slideUp(300, function () {
          $(this).parent().parent().hide();
        });
    }

    $this.removeClass('active');
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const tabs = {};
  document.querySelectorAll('a[role="tab"]').forEach(element => {
    tabs[element.hash] = {
      header: element,
      block: document.getElementById(element.hash.slice(1)),
    };
  });

  const links = document.querySelectorAll('a[role="super-link"]');
  links.forEach(element => {
    element.addEventListener('click', e => {
      let switchedTab = tabs[element.hash];
      if (window.location.pathname != element.pathname || !switchedTab) {
        return;
      }
      e.preventDefault();
      let activeTab = {
        header: document.querySelector('.nav-item[aria-selected="true"]'),
        block: document.querySelector('.tab-pane.active.show[aria-labelledby]'),
      };
      activeTab.header.setAttribute('aria-selected', 'false');
      activeTab.header.classList.remove('active');
      activeTab.block.classList.remove('active', 'show');

      switchedTab.header.setAttribute('aria-selected', 'true');
      switchedTab.header.classList.add('active');
      switchedTab.block.classList.add('active', 'show');
    });
  });
});

const phoneShowText = document.querySelector('.footer-phone-show');
const phone = document.querySelector('.footer-phone-hide');

phoneShowText.addEventListener('click', () => {
  requestAnimationFrame(() => {
    phoneShowText.style.display = 'none';
    phone.style.display = 'block';
  });
});
