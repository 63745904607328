import {Autocomplete, EVENT_TYPE_UPDATE_VALUE} from './index';
import { findAll, importTemplate } from '../../utils/dom';
import { mapTemplate, preventEvent } from '../../utils/etc';
import { EventBus } from '../event/event-bus';
import Event from '../event/event';

export const EVENT_TYPE_ITEM_ADDED = 'item_added';
export const EVENT_TYPE_ITEM_REMOVED = 'item_removed';

export class MultipleAutocomplete extends EventBus {
  constructor(wrapper) {
    super(false);

    this.selectedList = wrapper.querySelector('[data-role="selected-items"]');
    this.selectedList.className = 'dialog-selected__items';
    this.dataInput = wrapper.querySelector('[data-role="data-input"]');
    this.template = wrapper.querySelector('[data-role="result-template"]');
    this.dataInput.form && this.dataInput.form.addEventListener('reset', this.formResetHandler.bind(this));
    this.value = `${this.dataInput.value}`.split(',');
    this.selectedModels = [];

    this.selectedList.addEventListener('click', this.selectedListClickHandler.bind(this));
    this.autocompleteUpdateValueHandler = this.autocompleteUpdateValueHandler.bind(this);

    this.autocomplete = new Autocomplete(wrapper);
    this.autocomplete.subscribe(
        EVENT_TYPE_UPDATE_VALUE,
        this.autocompleteUpdateValueHandler
    );

    if (this.autocomplete.config.initialData && this.autocomplete.config.initialData.length > 0) {
      this.selectedModels = this.autocomplete.config.initialData;
      this.loadInitData(this.autocomplete.config.initialData);
    }
  }

  renderSelectedItem(item) {
    const template = importTemplate(this.template);
    mapTemplate(template, item, this.autocomplete.config.dataMapping);

    const removeBtn = template.querySelector('[data-role="remove-btn"]')
    if (item.permanent && removeBtn) {
      removeBtn.parentNode.removeChild(removeBtn);
    }

    return template
  }

  loadInitData(initData) {
    const fragment = document.createDocumentFragment();
    for (const item of initData) {
      fragment.appendChild(this.renderSelectedItem(item));
    }

    requestAnimationFrame(() => {
      this.selectedList.appendChild(fragment);
    });
  }

  autocompleteUpdateValueHandler({extra}) {
    this.autocomplete.input.value = '';

    const value = extra.get('value');
    const title = extra.get('title');
    const itemConfig = extra.get('item');

    if (this.value.indexOf(value) >= 0) {
      return;
    }

    this.value.push(value);
    this.updateDataInputValue();

    if (this.autocomplete.config.templateSupport) {
      requestAnimationFrame(() => {
        this.selectedList.appendChild(this.renderSelectedItem(itemConfig));
      });

      this.selectedModels.push(itemConfig);
      const event = new Event(EVENT_TYPE_ITEM_ADDED, this);
      event.extra.set('value', value);
      event.extra.set('title', title);
      event.extra.set('item', itemConfig);

      this.dispatch(EVENT_TYPE_ITEM_ADDED, event);

      return;
    }

    const item = document.createElement('div');
    item.innerHTML = title;
    item.dataset.value = value;
    item.dataset.action = 'remove';
    item.className = 'btn btn--multiple';

    const icon = document.createElement('i');
    icon.className = 'material-icons-outlined';
    icon.innerHTML = 'cancel';
 
    item.appendChild(icon);

    requestAnimationFrame(() => {
      this.selectedList.appendChild(item);
    });
  }

  selectedListClickHandler(event) {
    let selectedItem = event.target;

    if (event.target.dataset.action !== 'remove') {
      return;
    }

    if(event.target.dataset.marker !== 'wrapper') {
      selectedItem = event.target.closest('[data-marker="wrapper"]');
    }

    if (!selectedItem) {
      return;
    }

    preventEvent(event);

    requestAnimationFrame(() => {
      this.selectedList.removeChild(selectedItem);
    });

    const key = selectedItem.dataset.value;
    const index = this.value.indexOf(key);
    if (index < 0) {
      return;
    }

    const modelIndex = this.selectedModels.findIndex((model) => {
      return +model.id === +key;
    });
    if (modelIndex > -1) {
      const event = new Event(EVENT_TYPE_ITEM_ADDED, this);
      event.extra.set('item', this.selectedModels[modelIndex]);
      this.dispatch(
        EVENT_TYPE_ITEM_REMOVED,
        event
      );

      this.selectedModels.splice(modelIndex, 1);
    }

    this.value.splice(index, 1);
    this.updateDataInputValue();
  }

  updateDataInputValue() {
    this.dataInput.value = this.value.join(',');
  }

  formResetHandler() {
    this.value = [];
    requestAnimationFrame(() => {
      this.dataInput.value = '';
      this.selectedList.innerHTML = '';
    });
  }
}

export default (context = document) => {
  const items = findAll(context, '[data-component="multiple-autocomplete"]');
  for (const item of items) {
    new MultipleAutocomplete(item);
  }
};
