import {findAll, htmlToDom, importTemplate, listenElement} from '../utils/dom';
import {mapTemplate, objectToFormData, preventEvent} from '../utils/etc';
import Event from './event/event';
import EventBus from './event/event-bus';

class LoadMore {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.isLoad = false;
    this.list = wrapper.querySelector('[data-role="list"]');
    this.button = listenElement(wrapper, '[data-role="load-btn"]', 'click',
        this.loadBtnClickHandler.bind(this));
    this.config = {
      data: {},
      offset: 0,
      total: 0,
      url: '',
      method: 'POST',
      jsonSupport: false,
      dataMap: {},

      ...(window.__moni.components.loadMore[wrapper.dataset.id]),
    };

    this.checkButton();

    if (this.config.jsonSupport) {
      this.template = wrapper.querySelector('[data-role="template"]');
    }
  }

  loadBtnClickHandler(event) {
    preventEvent(event);

    if (this.isLoad) {
      return;
    }

    this.config.data.offset = this.list.childElementCount;

    this.startLoad();
    fetch(this.config.url, {
      method: this.config.method,
      body: objectToFormData(this.config.data),
    }).then(response => {
      if (response.status !== 200) {
        return Promise.reject(response.text());
      }

      return this.config.jsonSupport ? response.json() : response.text();
    }).then((response) => {
      if (this.config.jsonSupport) {
        return this.processJsonResponse(response);
      } else {
        return htmlToDom(response);
      }
    }).then((dom) => {
      const event = new Event('domAdded', this.wrapper);
      event.extra.set('wrapper', dom);
      EventBus.dispatch(EventBus.EVENT_DOM_ADDED, event);

      requestAnimationFrame(() => {
        this.list.appendChild(dom);
        this.checkButton();
      });
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.stopLoad();
    });
  }

  processJsonResponse(json) {
    const fragment = document.createDocumentFragment();

    for (const item of json) {
      const template = importTemplate(this.template);
      mapTemplate(template, item, this.config.dataMap);
      fragment.appendChild(template);
    }

    return fragment;
  }

  startLoad() {
    this.button.classList.add('js-wait');
    this.isLoad = true;
  }

  stopLoad() {
    this.button.classList.remove('js-wait');
    this.isLoad = false;
  }

  checkButton() {
    if (this.button && this.list.childElementCount >= this.config.total) {
      requestAnimationFrame(() => {
        this.button.style.display = 'none';
      });
    }
  }
}

export default (context = document) => {
  const items = findAll(context, '[data-component="load-more"]');
  for (const item of items) {
    new LoadMore(item);
  }
};
