import { preventEvent } from '../../utils/etc';
import { EventBus } from '../event/event-bus';
import PopupEvent from '../event/event';

const defaultConfig = {
  targetSelector: '[data-role="menu-trigger"]',
  context: document.body,
  normalState: 'close',
};

let activeMenu = null;

export const EVENT_MENU_ITEM_CLICK = 'EVENT_MENU_ITEM_CLICK';

export class PopupMenu extends EventBus {
  constructor(wrapper, config = {}) {
    super(false);

    this.id = wrapper.dataset.id;
    this.config = {
      ...defaultConfig,
      ...(window.__moni.components.popupMenus[this.id] || {}),
      ...config,
    };
    this.isOpen = false;
    this.wrapper = wrapper;
    this.wrapperClasses = this.wrapper.classList;
    this.wrapperStyle = this.wrapper.style;
    this.wrapperStyle.position = 'absolute';
    this.target = this.config.context.querySelector(this.config.targetSelector);

    if (!this.target && this.config.targetSelector) {
      throw Error(`Can not find menu target component with selector ${this.config.targetSelector}`);
    }

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);

    this.target && this.target.addEventListener('click', this.open);

    const items = wrapper.querySelectorAll('[data-role="menu-item"]');
    for (const item of Array.from(items)) {
      item.addEventListener('click', () => {
        const event = new PopupEvent(EVENT_MENU_ITEM_CLICK, this);
        event.extra.set('item', item);
        this.dispatch(EVENT_MENU_ITEM_CLICK, event);

        setTimeout(this.close, 100);
      });
    }

    requestAnimationFrame(() => {
      document.body.appendChild(wrapper);
    });

    if (config.normalState === 'open') {
      this.open();
    } else {
      this.close();
    }
  }

  close() {
    document.removeEventListener('click', this.close);
    this.isOpen = false;
    activeMenu = null;

    this.wrapperClasses.add('popup-menu_animate-out');
    setTimeout(() => {
      requestAnimationFrame(() => {
        this.wrapperStyle.display = 'none';
        this.wrapperStyle.visibility = 'hidden';
        this.wrapperClasses.remove('popup-menu_animated');
        this.wrapperClasses.remove('active');
        this.wrapperClasses.remove('popup-menu_animate-out');
      });
    }, 150);
  }

  open(event) {
    event && preventEvent(event);

    if (activeMenu === this) {
      activeMenu.close();

      return;
    } else if (activeMenu) {
      activeMenu.close();
    }

    activeMenu = this;
    setTimeout(() => {
      document.addEventListener('click', this.close);
    }, 150);

    this.isOpen = true;
    const { x, y, height, width } = this.target.getBoundingClientRect();

    requestAnimationFrame(() => {
      this.wrapperStyle.display = '';
      const rect = this.wrapper.getBoundingClientRect();
      this.wrapperStyle.left = `${x + width / 2 - rect.width}px`;
      this.wrapperStyle.top = `${y + height / 2 + window.scrollY}px`;
      this.wrapperStyle.zIndex = 2;
      this.wrapperStyle.visibility = '';

      this.wrapperClasses.add('popup-menu_animate-in');
      setTimeout(() => {
        this.wrapperClasses.add('popup-menu_animated');
        this.wrapperClasses.add('active');
        this.wrapperClasses.remove('popup-menu_animate-in');
      }, 150);
    });
  }
}

export default (context = document) => {
  const menus = Array.from(context.querySelectorAll('[data-component="popup-menu"]'));
  for (const menu of menus) {
    new PopupMenu(menu);
  }
};
