import initExpertRequestBlock from './expert-request-to-company';
import initCompanyUserRequestItems from './user-company-request-item';
import initCompanyExpertItem from './company-expert-item';
import initDatePickers from './date-picker';
import initCustomSelects from './custom-select';
import initLoadMore from './load-more';
import EventBus from './event/event-bus';
import Event from './event/event';
import initFileFields from '../fields/file';
import initCompanyModal from './modal/company-modal';
import initMultipleAutocomplete from './autocomplete/muliple';
import initAutocomplete from './autocomplete';
import initFlashNotification from './flash-notification';
import initExtendedDatePicker from './form/extended-date-picker';
import initSimpleAjaxForm from './form/simple-ajax-form';
import initCardNumber from './card-number';
import initNotificationCard from './notification-card';
import initImageUploader from './image-uploader';
import initPopupMenu from './popup-menu';
import initRangedDataPicker from './form/ranged-data-picker';
import initRippleEffect from '../animations/ripple-effect';
import initAccordion from './accordion';

const domAddedHandler = event => {
    const wrapper = event.extra.get('wrapper');
    if (!wrapper) {
        return false;
    }

    initExpertRequestBlock(wrapper);
    initImageUploader(wrapper);
    initCompanyUserRequestItems(wrapper);
    initCompanyExpertItem(wrapper);
    initDatePickers(wrapper);
    initCustomSelects(wrapper);
    initFileFields(wrapper);
    initCompanyModal(wrapper);
    initAutocomplete(wrapper);
    initMultipleAutocomplete(wrapper);
    initFlashNotification(wrapper);
    initExtendedDatePicker(wrapper);
    initSimpleAjaxForm(wrapper);
    initCardNumber(wrapper);
    initNotificationCard(wrapper);
    initPopupMenu(wrapper);
    initRangedDataPicker(wrapper);
    initRippleEffect(wrapper);
    initAccordion(wrapper);
};

document.addEventListener('DOMContentLoaded', () => {
    EventBus.subscribe(EventBus.EVENT_DOM_ADDED, domAddedHandler);

    const event = new Event(EventBus.EVENT_DOM_ADDED, document);
    event.extra.set('wrapper', document);
    EventBus.dispatch(EventBus.EVENT_DOM_ADDED, event);


    initLoadMore(document);
});