export class GoogleMap {
    constructor(wrapper, config, initImmediately) {
        this.markers = new Map();
        this.config = {
            ... config,
            center: {
                lat: 50.4546600,
                lng: 30.5238000
            },
            zoom: 10
        };
        this.wrapper = wrapper;

        if (initImmediately) {
            this.initMap();
        }
    }

    initMap() {
        this.map = new google.maps.Map(this.wrapper, this.config);
    }

    addMarker(id, {lat, lng, onClick, title}) {
        const marker = new google.maps.Marker({
            title,
            optimized: true,
            position: {
                lat,
                lng
            },
            map: this.map
        });

        this.markers.set(id, marker);
    }

    removeMarker(id) {
        return this.markers.delete(id);
    }

    setCenter({lat, lng}) {
        this.map.setCenter({
            lat,
            lng
        });
    }

    setZoom() {

    }
}
