import {findAll, importTemplate, listenElement} from "../../utils/dom";
import {Modal} from "./index";
import {GoogleMap} from "../map";
import {preventEvent, stopPropagation} from "../../utils/etc";

class CompanyModal {
    constructor(trigger) {
        const {url, template} = trigger.dataset;
        const modalTemplate = importTemplate(document.querySelector(`[data-id="${template}"]`)).firstElementChild;

        this.url = url;

        this.title = modalTemplate.querySelector('[data-role="title"]');
        this.logo = modalTemplate.querySelector('[data-role="logo"]');
        this.address = modalTemplate.querySelector('[data-role="address"]');
        this.link = modalTemplate.querySelector('[data-role="link"]');
        this.mapWrapper = modalTemplate.querySelector('[data-role="map"]');
        this.card = modalTemplate.querySelector('[data-role="card"]');
        this.schedules = modalTemplate.querySelector('[data-role="schedules"]');

        listenElement(modalTemplate, '[data-role="modal-body"]', 'click', stopPropagation);

        this.modal = new Modal(modalTemplate);
        this.map = new GoogleMap(this.mapWrapper, {});

        trigger.addEventListener('click', this.triggerClickHandler.bind(this));
        this.link.addEventListener('click', stopPropagation);
    }

    triggerClickHandler(event) {
        preventEvent(event);

        this.map.initMap();
        this.modal.open();
        this.loadCompanyData();
    }

    loadCompanyData() {
        fetch(this.url)
            .then(resp => {
                if (resp.status !== 200) {
                    return Promise.reject(resp.text());
                }

                return resp.json();
            })
            .then(({
                       title = '',
                       address = '',
                       lat = '',
                       lng = '',
                       logo = '',
                       link = '',
                       schedules = []
                   }) => {
                const schedulesFragment = document.createDocumentFragment();
                for (const schedule of schedules) {
                    const span = document.createElement('span');
                    span.innerHTML = schedule;
                    schedulesFragment.appendChild(span);
                }

                requestAnimationFrame(() => {
                    this.title.innerHTML = title;
                    this.address.innerHTML = address;
                    this.logo.src = logo;
                    this.logo.alt = title;
                    this.link.href = link;

                    this.schedules.appendChild(schedulesFragment);

                    this.card.style.display = '';
                });

                const center = {
                    lat,
                    lng
                };

                this.map.setCenter(center);
                this.map.addMarker('company', {
                    label: title,
                    ...center
                });
            })
            .catch(err => console.error(err));
    }
}

export default (context = document) => {
    const triggers = findAll(context, '[data-component="company-modal-map"]');
    for (const trigger of triggers) {
        new CompanyModal(trigger);
    }
};
